@import "../../styles/theme.scss";

.header {
  align-items: center;
  background-color: rgb(43, 43, 43);
  flex-wrap: wrap;
  color: #fff;
}

#notification {
  padding: 10px !important;
  box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.1);
  display: flex;
}

.bell {
  flex: 0 0 25px;
  margin: 5px 10px 0 0;
}

.header.highlight {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: all 0.5s;
  padding: 10px 10px 10px 30px;
  opacity: 0.8;
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: 1260px !important;
  height: 50px;
}

.content.highlight {
  height: 40px;
}

.menus {
  margin: 0;
  list-style: none;
  white-space: nowrap;
  position: relative;
  bottom: 6px;
}

.menu {
  padding: 8px 20px 0;
  font-size: 13px;
  color: #fff;
  position: relative;

  &:last-child {
    padding-right: 0;
  }
}

.logoImg {
  height: 42px;
  padding-right: 10px;
  object-fit: contain;
}

.menu:last-child {
  margin-right: 0;
}

.signUp {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 5px 12px;
  color: #fff;
  display: inline-block;
  margin-top: 5px;
}

.signUp:hover {
  color: #00cfbf;
  border-color: #00cfbf;
}

.menuLink {
  padding-bottom: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  border-bottom: 2px solid transparent;
}

.menuLink.highlight {
  padding: 10px 0 12px;
  border-bottom: 5px solid transparent;
}

.menuLink:hover {
  color: #fff;
  border-bottom: 2px solid #fff;
}

.menuLink.highlight:hover {
  border-bottom: 5px solid #fff;
}

.downIcon {
  position: relative;
  top: 5px;
  left: 3px;
  transition: all 0.2s !important;
}

.active.downIcon {
  transform: rotate(180deg);
}

.active.menuLink {
  border-color: #fff;
  font-weight: 500;
  color: #fff;
}

.active.menuLink:hover {
  font-weight: 500;
}

.subMenus {
  position: absolute;
  z-index: 2;
  top: 42px;
  min-width: 155px;
  left: 5px;
  padding: 14px 30px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 20px rgba(96, 108, 136, 0.075);
}

.subMenu {
  font-size: 16px;
  color: #7c7c7c;
  line-height: 2.5;
}

.subMenu:hover,
.active.subMenu {
  color: #00cfbf;
}

.loginBtn {
  margin-left: 40px;
}

.circle {
  width: 52px;
  height: 52px;
  margin-left: 20px;
  background-color: #d8d8d8;
  border-radius: 50%;
}

.icon {
  display: flex;
  align-items: center;
  bottom: 3px;
}

.closeIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media screen and (max-width: $screen-lg) {
  .mobileMenus {
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    padding: 9px 30px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.85);
    min-height: 50px;
  }

  .mobileMenus.dark {
    opacity: 0.85;
    background-image: linear-gradient(
      to bottom,
      #3b3b3b,
      #232323 69%,
      #0f0f0f 86%,
      #3b3b3b
    );
  }

  .mobileLink {
    color: #fff;
  }

  .logoMobile {
    width: 230px;
  }

  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .menus {
    margin: 25px 0 10px;
  }

  .menu {
    display: block;
    font-size: 16px;
    padding: 8px 0 10px;
  }

  .shiftRight {
    padding-left: 30px;
  }

  .menuLink {
    border-bottom: none;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  .menuLink:hover {
    font-weight: normal;
    border-bottom: none;
  }

  .menuLink.active {
    color: #00cfbf;
  }

  .subMenus {
    position: static;
    min-width: 100px;
    padding: 0 0 0 40px;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .subMenu {
    display: block;
    font-size: 14px;
    color: #fff;
  }

  .downIcon {
    margin-right: 10px;
  }

  #notification {
    margin-top: 0px;
  }
}

@media screen and (max-width: $screen-sm) {
  .logoMobile {
    width: 190px;
    max-height: 27px;
  }

  #notification {
    margin-top: 0px;
  }
}
