$primaryColor: #606c88;
$primaryTextColor: #7c888e;
$primaryTextShadow: 0 2px 5px 0 rgba(255, 160, 51, 0.3);

$grayBorder: 1px solid #d2dae5;
$red: #ff5050;
$blue: #377dff;

$bgLight: #fbfcfe;
$bgDark: #606c88;

$validationRed: #ff5050;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 991px; // This MUST match the 'mobile' breakpoint in partials/Responsive/index.tsx for the NavBar to transition between mobile/desktop smoothly
$screen-xl: 1200px;
$screen-hg: 1440px;
$screen-ms: 1800px;
