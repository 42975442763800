@import "theme";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body,
pre {
  font-family: "Open Sans", sans-serif !important;
  line-height: 1.5;
  font-size: 16px;
  color: $primaryTextColor;
  height: 100%;
}

ul,
li {
  list-style: none;
}

.content {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 8px;
  flex: 1;
}

#main-content {
  padding-left: 120px;
}

h1,
h2,
h3,
h4 {
  margin: 1em 0;
  font-weight: 300;
  font-size: 50px;
  color: #3b3b3b;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

p {
  margin: 1em 0;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  color: #3b3b3b;
}

label {
  margin: 0;
  font-size: 15px;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  color: $blue;
  text-decoration: none;
}

input {
  padding: 10px;
  color: $primaryTextColor;
  border-radius: 4px;
  outline: none;
  border: $grayBorder;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  max-width: 100% !important;
}

table {
  margin: 2em 0;
  border-collapse: collapse;
}

tr {
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #ddd;
  }
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgba(59, 59, 59, 1);
  color: white;
}

pre {
  height: auto !important;
}


.list {
  padding-bottom: 5px;
}

.list1 {
  @extend .list;
 list-style-type: disc !important;
 margin-left: 1em;
 font-weight: bold;
 padding-bottom: 2px;
}
.list2 {
  @extend .list;
 list-style-type: circle !important;
 margin-left: 3em;
 font-weight: normal;
padding-bottom: 2px;
}
.list3 {
  @extend .list;
 list-style-type: square !important;
 margin-left: 5em;
 padding-bottom: 2px;
}

.list1n {
  @extend .list;
 list-style-type: disc !important;
 margin-left: 1em;
}

.flexGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexGridItem {
  flex-grow: 1;
  flex-basis: 0;
}

.itemInGrid {
  display: block;
  margin: auto;
}

@media screen and (max-width: $screen-lg) {
  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }
}
