@import "../../styles/theme.scss";

.wrapper {
  padding: 45px 0;
  background-color: rgb(59, 59, 59);
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 13px;
  color: #fff;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #fff;
}

.text:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.email {
  color: #fff;
}

.email:hover {
  color: #fff;
}

@media screen and (max-width: $screen-lg) {
  .text {
    font-size: 10px;
    padding-right: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: $screen-lg) {
  .wrapper {
    padding: 15px 0;
  }
}

@media screen and (max-width: $screen-sm) {
  .wrapper {
    padding: 15px 0;
    display: block;
  }

  .text {
    font-size: 10px;
    border-right: none;
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 10px;
    text-align: center;
  }

  .text:last-child {
    padding-bottom: 0;
  }
}
