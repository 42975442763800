@import "theme";

.margin {
  margin: 100px auto;
}

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-right {
  margin-right: 20px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.content-full-height {
  height: calc(100% - 200px);
}

input.small {
  height: 34px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-only {
  display: flex;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.text-center {
  margin: 0 auto;
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.bold {
  font-weight: 700;
  color: #354052;
}

.relative {
  position: relative;
}

@media screen and (max-width: $screen-lg) {
  .content-full-height {
    height: calc(100% - 45px);
  }
}

@media screen and (max-width: $screen-md) {
  .no-data {
    border-left: none;
    border-right: none;
  }

  .margin {
    margin: 0;
  }
}

.blue {
  color: $primaryColor;
}

.red {
  color: $validationRed;
}

.no-border {
  border: none;
}

.no-border-bottom {
  border-bottom: none;
}

.border-top {
  border-top: $grayBorder;
}

.border-right {
  border-right: $grayBorder;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}

.font500 {
  font-weight: 500;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.mt-2em {
  margin-top: 2em;
}

.mb-2em {
  margin-bottom: 2em;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-2em {
  padding-bottom: 2em;
}

.pb-1em {
  padding-bottom: 1em;
}

.uppercase {
  text-transform: uppercase;
}

.warning-message {
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fffaf3;
  color: #573a08;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
}
