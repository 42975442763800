@import "../../styles/theme.scss";

.layout {
  height: 100%;
}

@media screen and (max-width: $screen-lg) {
  .layout {
    padding-top: 0;
  }
}
